
@mixin make-toggle-switch-active(
  $color: $color1,
  $bg: fadeout($color, 40%),
) {
  background-color: $bg;

  &::before {
    background-color: $color;
    transform: translateX(30px - (20px / 2));
  }
}

.toggle-switch {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  height: 14px;
  width: 30px;
  background-color: rgba(0, 0, 0, 0.38);
  vertical-align: middle;
  margin: 4px;
  border-radius: 7px;
  transition: all 300ms;

  input {
    display: none;
  }

  &::before {
    content: "";
    display: inline-flex;
    width: 20px;
    height: 20px;
    background-color: white;
    border-radius: 20px;
    margin: 0 -6px;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
    transition: inherit;
  }

  &.active {
    @include make-toggle-switch-active();
  }
}
