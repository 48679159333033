#product_summary.dialog {
  .nav-container {
    align-items: center;
    background-color: $color-white;
    border-bottom: 2px solid $border-color;
    display: flex;
    flex-direction: row;
    height: 70px;
    width: 100%;

    .subnav {
      border-bottom: none;
      flex: 1 1;
      padding: 20px;
      position: relative;

      ul {
        display: inline-block;

        li {
          border-right: 2px solid $border-color;
          cursor: pointer;
          display: inline-block;
          padding-left: 1em;
          padding-right: 1em;
          text-transform: initial;

          &:last-child {
            border: none;
          }

          &.ui-state-active a {
            color: $accent-color;
          }

          span {
            vertical-align: middle;

            &.summary_icon {
              background-position: center center;
              background-repeat: no-repeat;
              display: inline-block;
              height: 25px;
              margin-right: 0;
              position: relative;
              width: 25px;

              &.save {
                background-image: url('../img/icon_save.png');
              }

              &.download_render {
                background-image: url('../img/icon_download_render.png');
              }

              &.download {
                background-image: url('../img/icon_download.png');
              }

              &.email {
                background-image: url('../img/icon_email.png');
              }

              &.share {
                background-image: url('../img/icon_share.png');
              }
            }
          }
        }
      }
    }

    .dialog_close {
      padding-right: 20px;
      position: unset;
      right: unset;
      top: unset;
    }
  }

  .summary_item {
    border-bottom: 2px solid $border-color;
    margin-bottom: 2.5em;
    position: relative;

    &::after {
      @include clear;
    }

    &:last-of-type {
      margin-bottom: -2em;
    }

    .no_preview {
      // need to override margin set for preview
      margin-left: 0 !important;
    }

    .summary_info {
      float: left;
      padding-bottom: 1.5em;
      width: 200px;

      h3 {
        color: $accent-color;
        font-weight: 400;
        margin-bottom: 0.75em;
        text-transform: uppercase;
      }

      .summary_swatch {
        background-color: $border-color;
        background-position: center center;
        background-repeat: no-repeat;
        margin-bottom: 0.75em;
        padding-top: 100%;
        width: 100%;
      }

      h4 {
        font-weight: 400;
      }
    }

    .summary_table {
      display: block;
      margin-left: 265px;

      table {
        border-collapse: collapse;
        width: 100%;

        tr {
          th,
          td {
            border-bottom: 2px solid $border-color;
            padding: 0.75em 0;
            text-align: left;
            width: 25%;
          }

          th {
            font-weight: 400;
          }
        }

        thead th:nth-child(1) {
          color: $font-color-light;
          font-size: 1.1em;
          text-transform: uppercase;
        }

        tbody {
          tr:last-child td {
            border-bottom-style: none;
          }

          td {
            vertical-align: top;

            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              font-size: 1.25em;
              font-weight: 700;
            }

            &:nth-child(2) {
              color: $accent-color;
            }

            &:nth-child(3) {
              color: $color4;
            }

            &:nth-child(4) {
              color: $font-color;
            }
          }
        }
      }
    }

    .grid_summary_cell {
      padding: 0 !important;

      // Unset font changes so that elements are aligned
      p {
        font-size: initial;
        font-weight: initial;
      }
    }

    .grid_summary_item {
      display: flex;

      .grid_item_swatch {
        height: 25px;
        margin-left: 10px;
        width: 25px;
      }
    }
  }

  @media (max-width: $screen-md-max) {
    .section {
      padding: 10px 20px;

      .summary_item {
        .summary_info {
          width: 140px;
        }

        .summary_table {
          left: 155px;

          th {
            padding: 0.2em;
            padding-top: 0;
            width: 20%;

            &:first-child {
              width: 40%;
            }
          }

          td {
            padding-left: 0.2em;
            padding-right: 0.2em;
            width: 20%;

            &:first-child {
              width: 40%;
            }
          }
        }
      }
    }

    .subnav {
      font-size: 1.2em;
      height: 50px;
      padding: 10px 0;

      ul {
        display: block;
        width: 100%;

        li {
          display: block;
          float: left;
          overflow: hidden;
          padding-left: 0.25em;
          padding-right: 0.5em;
          text-align: center;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 25%;

          span.summary_icon {
            margin-right: 0;
          }

          // Hide text, since it's barely visible on mobile
          span.locale_html {
            display: none;
          }
        }
      }
    }
  }

  @media (orientation: portrait) {
    .section {
      padding: 10px;

      .summary_item {
        .summary_info {
          float: none;
          padding-bottom: 0.5em;
          width: 100%;

          .profile_name,
          .color_name {
            font-size: 1.5em;
          }
        }

        .summary_table {
          left: auto;
          position: relative;
          right: auto;
        }
      }
    }
  }
}
