.dialog_close,
.notification_close {
  cursor: pointer;
  display: inline-block;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 0;
}

.close_icon {
  position: relative;
  width: 15px;
  height: 15px;
  color: $font-color;
  text-align: center;

  &::after,
  &::before {
    background-color: currentColor;
    content: ' ';
    height: 100%;
    position: absolute;
    width: 2px;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }

  &.md {
    height: 20px;
    width: 20px;
  }

  &.lg {
    color: $color1;
    height: 25px;
    width: 25px;
  }
}

.dialog {
  bottom: 0;
  display: none;
  left: 0;
  margin: 0;
  padding: 40px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;

  > * {
    left: 50%;
    margin: auto;
    max-height: calc(100% - 40px * 2);
    max-width: 400px;
    overflow-y: auto;
    padding: 40px;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &.page,
  &.dialog_lg {
    > * {
      height: 100%;
      max-width: calc(100% - 40px * 2);
      padding: 0;
      width: 100%;
    }
  }

  &.dialog_sm {
    text-align: center;

    .title {
      margin-bottom: 20px;
    }

    .footer {
      margin-bottom: -10px;
      margin-top: 30px;

      .btn {
        margin: 10px !important; // override JS-added margin in core's Notify.show()
      }
    }
  }

  @media (max-width: $screen-md-max) {
    padding: 10px;

    > * {
      max-height: calc(100% - 10px * 2);
      max-width: calc(100% - 10px * 2) !important; // include .page.dialog
      width: 100%;
    }
  }
}
