.hide_big {
  display: none;
}

.hide_big_inline {
  display: none;
}

.hide_big_inline_block {
  display: none;
}

@media (max-width: $screen-md-max) {
  #header {
    height: $header-height-mobile;
    padding: 0 1em 0 0;

    #logo {
      margin-right: auto;
      padding: 0 15px;
      width: 95px;
    }

    .hamburger_button {
      cursor: pointer;
      display: block;
      height: 30px;
      line-height: 30px;
      text-align: center;

      .fa {
        font-size: 1.75em;
      }
    }

    nav {
      background-color: #333;
      bottom: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      padding: 0 20px;
      position: fixed;
      right: 0;
      top: $header-height-mobile;
      transform: translateX(100%);
      transition: all 350ms;
      transition: all 0.2s ease 0.1s;
      width: $hamburger-width;

      .header_buttons {
        align-items: unset;
        align-self: flex-start;
        flex-direction: column;
        margin: 0;

        a {
          padding-left: 0;
          padding-top: 24px;

          &:first-of-type {
            border: none;
          }
        }
      }

      .header_login {
        align-self: flex-start;
        margin: 0;
        padding-top: 24px;

        i {
          display: none;
        }

        a {
          margin: 0;
        }
      }

      .divider {
        display: none;
      }

      label {
        display: none;
      }

      .language_bar {
        display: flex;
        margin-top: 20px;
        width: 100%;

        .btn {
          font-size: 12px;
          padding: 6px;
        }
      }
    }

    &.open {
      nav {
        transform: translateX(0);
      }

      + #content {
        transform: translateX(-$hamburger-width);
      }
    }
  }

  #content {
    margin-top: $header-height-mobile;
    transition: all 350ms;

    > .page {
      &.modal_border {
        border-width: 0;
      }

      .main {
        padding-left: 10px;
        padding-right: 10px;
      }

      .sidebar {
        transition: all 0.2s ease 0.1s;
        width: 180px;

        &.horizontal {
          height: 40px;
          margin: 0;
          padding: 5px;
        }

        .section {
          padding: 0 2%;
          top: 0 !important;
        }

        ul li a:hover::before {
          content: initial !important;
        }
      }
    }
  }

  .hide_small {
    display: none !important;
  }

  .hide_big {
    display: block !important;
  }

  .hide_big_inline {
    display: inline !important;
  }

  .hide_big_inline_block {
    display: inline-block;
  }
}

@media (orientation: portrait) {
  #header {
    &.open {
      + #content .sidebar.fixed {
        left: 0 !important;
        right: 0 !important;
      }
    }

    nav ul li {
      white-space: nowrap;
    }
  }

  #content {
    .sidebar {
      &.horizontal {
        height: 36px !important;
        overflow: initial !important;

        .section {
          float: none !important;
          padding: 0% !important;

          > ul,
          > p {
            display: none;
          }
        }

        ul li {
          float: none;
          margin-left: 12px;
          padding-left: 0;

          a {
            padding: 0;
          }
        }
      }
    }

    .paginator {
      bottom: 0;
      left: 0;
      position: absolute;
      right: 0;
      top: 0 !important;
    }
  }
}
