#upload_scene.page {
  .main {
    display: flex;
    flex-direction: column;

    .upload-info-container {
      text-align: center;

      h2,
      p {
        margin: 0 0 10px;
        padding: 0;
      }
    }

    hr {
      display: none;
    }

    form {
      display: flex;
      overflow: auto;

      div.message.error {
        margin: 0;
        margin-top: 0.8em;
        padding: 0;
        padding-top: 15px;
      }
    }

    .half {
      padding-bottom: 25px;
      padding-top: 25px;
      width: 50%;

      h1,
      .dragonDrop-dropzone h2,
      h3 {
        color: $color1;
        font-size: 25px;
        font-weight: 900;
        margin-bottom: 20px;
      }

      &.step1 {
        .dragonDrop-wrapper {
          display: flex;

          .left {
            flex: 6;

            .dragonDrop-dropzone {
              background-color: $border-color;
              color: $color1;
              cursor: pointer;
              display: block;
              height: 300px;
              position: relative;
              width: 100%;

              h2 {
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                white-space: nowrap;
              }

              .image_list {
                display: grid;
                grid-template-columns: 1fr;
                height: 100%;

                .image {
                  background-position: center center;
                  background-repeat: no-repeat;
                  background-size: contain;
                  margin: 5px;
                  position: relative;

                  .delete {
                    bottom: 0;
                    padding: 5px 10px;
                    position: absolute;
                    right: 0;
                  }
                }
              }

              &.has_images {
                h2 {
                  display: none;
                }
              }
            }
          }

          .right {
            flex: 4;
          }
        }

        .browse_box {
          margin: 20px 0;

          input {
            height: 0;
            position: absolute;
            visibility: hidden;
            width: 0;
          }
        }

        .buyflow_options {
          article {
            margin-top: 40px;

            header {
              display: flex;
              align-items: center;
              color: $color1;
              font-weight: bold;

              > *:first-child {
                flex: auto;
              }
            }
          }
        }
      }

      &.step2 {
        p {
          margin-bottom: 10px;
        }

        textarea {
          margin-top: 10px;
          width: 100%;
        }

        .btn {
          margin-top: 20px;
        }

        .for_ds {
          &.disabled {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }
    }

    @media (max-width: $screen-md-max) {
      padding-left: 25px;
      padding-right: 25px;

      hr {
        border: 0;
        border-top: solid 1px $border-color;
        display: block;
        margin: 15px 0;
      }

      form {
        flex-direction: column;
      }

      .half {
        width: 100%;

        &.step1 {
          border-bottom: 1px solid $font-color-light;
          margin-bottom: 20px;

          .dragonDrop-wrapper {
            flex-direction: column-reverse;

            .best_results {
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    @media (min-width: $screen-lg-min) {
      .half {
        margin-top: 25px;

        &.step1 {
          border-right: 1px solid $font-color-light;
          padding-right: 25px;

          .dragonDrop-wrapper {
            .best_results {
              margin-left: 20px;
            }
          }

          .browse_box {
            align-items: center;
            display: flex;
            justify-content: space-between;

            > * {
              margin: 0;
            }
          }
        }

        &.step2 {
          padding-left: 25px;
        }
      }
    }
  }
}
