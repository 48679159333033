@font-face {
  font-family: 'Helvetica LT Std';
  font-weight: 400;
  src: url('../font/HelveticaLTStd-Roman.otf') format('opentype');
}

@font-face {
  font-family: 'Helvetica LT Std';
  font-weight: 700;
  src: url('../font/HelveticaLTStd-Bold.otf') format('opentype');
}

@font-face {
  font-family: 'Helvetica LT Std';
  font-weight: 900;
  src: url('../font/HelveticaLTStd-Blk.otf') format('opentype');
}

// Sizes
$header-height: 64px;
$project-toolbar-height: 64px;
$hamburger-width: 200px;
$header-height-mobile: 36px;
$langBoxHeight: 28px;
$product-menu-width: 300px;
$product-submenu-width: 500px;
$product-submenu-width-dense: $product-submenu-width - 100px;
$product-menu-padding: 30px;

// Breakpoints
$screen-xl-min: 1200px;
$screen-lg-max: $screen-xl-min - 1px;
$screen-lg-min: 1024px;
$screen-md-max: $screen-lg-min - 1px;
$screen-md-min: 768px;
$screen-sm-max: $screen-md-min - 1px;
$screen-sm-min: 480px;
$screen-xs-max: $screen-sm-min - 1px;

// Font stack
$font-stack: 'Helvetica LT Std', 'Helvetica', sans-serif;

// Transitions
$default-transition: all 0.2s ease;

// Colors
$color-white: #fff;
$color-grey: #e0e0e0;
$color-dark-grey: #202020;
$font-color: #595959; // Primary font color
$font-color-light: #b4b4b4; // Light font color
$border-color: $font-color-light; // Border and outline color
$sidebar-color: #e9ecf1;
$color1: #1c0856; // Primary color
$color2: #383e8a; // Secondary color
$color3: #6b4bab; // Tertiary color
$color4: #8f8fb9; // Quaternary color - light color
$accent-color: $color1;
$help-bg-color: $color3; // Help popup background color
