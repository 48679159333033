#share_design .section {
  display: flex;
  flex-direction: column;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 720px;

  h1 {
    text-align: center;
  }

  .social-buttons {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;

    a {
      display: block;
      float: left;
      font-size: 1.2em;
      text-align: center;
      transition: all 0.4s ease;

      span {
        display: block;
      }
    }
  }
}

#email_project {
  form[name='email_to_friend'] input[type='text'] {
    padding-left: 0.7em;
    padding-right: 0.7em;
  }

  .required_asterisk {
    margin-left: -22px;
    margin-top: 5px;
  }

  .message {
    padding: 0;
  }
}

#email_project .required_asterisk {
  margin-top: 5px;
}

@media (max-width: $screen-md-max) {
  #share_design.page .section h1 {
    display: block !important;
  }

  #share_design.page {
    height: 100%;
  }

  #share_design .section {
    left: initial !important;
    margin: 0 auto !important;
    margin-top: 50% !important;
    position: relative !important;
    transform: translateY(-70%) !important;
    width: auto !important;
  }

  #share_design .section::after {
    clear: both;
    content: '.';
    display: block;
    height: 0;
    visibility: hidden;
  }
}
