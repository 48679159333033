/* -- General -- */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow: hidden;
}

body {
  -webkit-font-smoothing: antialiased;

  #combobox-dropdown-menu2 {
    bottom: 0 !important;
    left: auto !important;
    right: 0 !important;
    top: auto !important;
    width: 170px;
  }

  &.logged_in .when_logged_out,
  &:not(.logged_in) .when_logged_in {
    display: none !important;
  }

  &:not(.ds-user) .when-ds-user {
    display: none !important;
  }
}

a {
  text-decoration: none;

  &:focus {
    outline-style: none;
  }
}

ul {
  list-style-type: none;

  &.bullets {
    list-style: initial;
    line-height: 1.5;
    margin: 10px 0 10px 20px;
  }
}

.hidden {
  display: none !important;
}

#loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10001;

  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.spinner {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.split-columns {
  display: flex;
  height: 100%; // fixes home page layout on IE11
  min-height: 100%;

  // Set height constraint to allow overflow
  @media (max-height: $screen-sm-max) {
    height: unset;
  }

  > * {
    flex: 1 0;
  }
}

/* -- Header Structure -- */
#header {
  align-items: center;
  background-color: $color1;
  display: flex;
  height: $header-height;
  left: 0;
  padding: 0 $product-menu-padding;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;

  #logo {
    cursor: pointer;
    position: relative;

    img {
      height: auto;
      max-width: 100%;
      vertical-align: middle;
    }
  }

  nav {
    align-items: center;
    display: flex;
    width: 100%;

    label {
      $dropdown-size: 15px;

      display: flex;
      margin-right: 20px;
      position: relative;

      select {
        appearance: none;
        background: none;
        border: none;
        color: $color-white;
        padding-right: $dropdown-size + 10px;
        z-index: 2;

        option {
          color: $color1;
        }

        &::-ms-expand {
          display: none;
        }
      }

      &::after {
        background-image: url('../../../sites/royal/img/chevron_down.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        height: $dropdown-size;
        position: absolute;
        right: 0;
        width: $dropdown-size;
        z-index: 1;
      }
    }

    .header_buttons {
      align-items: center;
      display: flex;
      margin: 0 auto;

      a {
        padding: 0 15px;

        sup {
          color: #fc0;
          font-size: 0.66em;
          text-transform: uppercase;
        }

        &:not(:last-child) {
          border-right: 1px solid $color-white;
        }
      }
    }

    .header_login {
      align-items: center;
      display: flex;
      font-weight: bold;

      i {
        font-size: 36px;
      }

      a {
        margin-left: 10px;
      }
    }

    .language_bar {
      display: none;
    }
  }

  .hamburger_button {
    display: none;

    .fa {
      font-size: 1.75em;
    }
  }

  .logged_in & {
    nav {
      .header_buttons {
        .your_projects {
          display: initial;
        }

        .start_designing {
          border-right: 1px solid $color-white;
        }
      }
    }
  }
}

/* -- Page Structure -- */
#wrapper {

  // @HACK: to solve overlapping issue on iOS
  //        fallback for WebKit browsers (iOS Safari)
  //        https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  //        https://allthingssmitty.com/2020/05/11/css-fix-for-100vh-in-mobile-webkit/
  @include safariOnly((
    height: -webkit-fill-available,
    min-height: -webkit-fill-available
  ));

  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: 100vh;
}

#content {
  background: url('../img/background.jpg') center center no-repeat;
  background-size: cover;
  display: flex;
  flex: 1 0;
  flex-direction: column;
  margin-top: $header-height;
  overflow: hidden;
  position: relative;
  transition: all 0.2s ease 0.1s;

  > .page,
  > .dialog_lg {
    display: none;
    height: 100%;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: absolute;
    top: 0;
    width: 100%;

    &.modal_border {
      background-color: transparent;
      border-color: rgba(0, 0, 0, 0.75);
      border-style: solid;
      border-width: 30px 50px;
      bottom: 0;
    }

    .section {
      padding: 40px;
    }

    .third {
      display: block;
      float: left;
      width: 33.33%;
    }

    .subnav {
      align-items: center;
      background-color: $color-white;
      border-bottom: 1px solid $border-color;
      display: flex;
      font-size: 1em;
      font-weight: 700;
      height: 64px;
      padding: 21px;
      position: relative;
      text-transform: uppercase;

      ul {
        display: inline-block;

        li {
          border-right: 2px solid $border-color;
          display: inline-block;

          &:last-child {
            border: none;
          }

          &.ui-state-active a {
            color: $accent-color;
          }

          a {
            padding: 5px 30px;
            transition: all 0.2s ease;

            &:hover,
            &.selected {
              color: $accent-color;
            }
          }
        }
      }

      @media (max-width: $screen-md-max) {
        font-size: 0.9em;
      }
    }

    > .main {
      background-color: $color-white;

      /* default on the left side */
      display: block;
      padding: 50px;
      width: 66.67%;

      &.right {
        left: 33.33%;
      }

      &.nosidebar {
        width: 100%;
      }
    }

    .half {
      float: left;
      height: 100%;
      position: relative;
      width: 50%;
    }

    .sidebar {
      background-color: $sidebar-color;
      bottom: 0;

      /* default on the right side */
      display: block;
      height: 100%;
      left: 66.67%;
      padding: 50px;
      position: absolute;
      right: 0;
      top: 0;

      &.left {
        left: 0;
        width: 33.33%;
      }

      &:not(.horizontal) ul li a {
        &:hover::before,
        &.selected::before {
          content: '\f0da';
          font-family: 'FontAwesome';
          font-size: 0.75em;
          left: 3px;
          position: absolute;
          top: 5px;
        }
      }

      h3 {
        border-bottom: 1px solid $border-color;
        margin-bottom: 0.75em;
        padding-bottom: 0.25em;
      }

      ul {
        display: block;

        li {
          display: block;
          padding-left: 12px;
          position: relative;

          a {
            display: block;
          }
        }
      }

      .section {
        padding: 0 14%;
      }
    }
  }

  .project_image,
  .project_image_temp {
    background: no-repeat center center fixed #000;
    background-size: cover;
    cursor: pointer;
    overflow: hidden;
    transition: left 0.2s ease, right 0.2s ease 0.1s;
  }

  .paginator {
    padding: 0;

    &::after,
    .body::after {
      clear: both;
      content: '.';
      display: block;
      height: 0;
      visibility: hidden;
    }
  }

  input {
    &:required {
      box-shadow: none;
    }

    &.block {
      display: block;
      width: 100%;
    }
  }

  /* -- Form Defaults -- */
  input:not([type='button']):not([type='checkbox']):not([type='radio']):not(.combobox-textfield),
  textarea,
  select {
    background-color: $color-white;
    border: 1px solid $border-color;
    border-radius: 2px;
    height: 40px;
    margin-bottom: 0.75em;
    max-width: 100%;
    padding: 0.5em 0.75em;
    text-align: left;

    &:focus {
      outline: none;
    }
  }

  textarea {
    height: 100px;
    min-height: 40px;
    resize: vertical;
  }

  .required_asterisk {
    color: #f60;
    display: inline-block;
    font-size: 0.65em;
    margin-left: -26px;
    margin-top: 14px;
    position: absolute;
    text-align: center;
    width: 26px;

    &::before {
      content: '\f069';
      font-family: 'FontAwesome';
    }
  }

  fieldset {
    border-style: none;
    margin-top: 1em;
    outline-style: none;
    position: relative;

    legend {
      display: block;
      margin-bottom: 0.75em;
    }

    p {
      padding: 6px 8px;
    }
  }

  form div.input_checkbox {
    border: 1px solid $border-color;
    display: block;
    display: inline-block;
    height: 24px;
    position: relative;
    top: 2px;
    width: 24px;

    label {
      background: url('../img/icon_small_checkmark.png') transparent no-repeat;
      background-size: cover;
      bottom: 0;
      cursor: pointer;
      display: block;
      left: 0;
      margin-bottom: 0 !important;
      opacity: 0;
      position: absolute !important;
      right: 0;
      top: 0;
    }

    input[type='checkbox'] {
      visibility: hidden;

      &:checked + label {
        opacity: 1;
      }

      + label {
        cursor: pointer;
        display: inline-block !important;
        margin-bottom: 0 !important;
      }
    }
  }

  .combobox-wrapper {
    input.combobox-textfield {
      border: 1px solid $border-color;
      font-size: 0.9em;
      font-weight: normal;
      padding: 0 20px;
    }

    .combobox-dropdown-menu {
      z-index: 9999;
    }
  }

  .empty_warning_message {
    &::before,
    &::after {
      content: '- ';
    }
  }

  .message {
    border: 1px solid $color-white;
    display: block;
    display: none;
    margin: 12px;
    padding: 12px;

    ul {
      list-style-type: none;
    }
  }
}

/* -- Defaults for some handy stuff -- */
.clr {
  clear: both;
}

.dynamic_overlay {
  bottom: 0;
  display: none;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.btn {
  border: none;
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  font-size: 1em;
  font-weight: bold;
  outline: none;
  padding: 15px 30px;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  white-space: nowrap;

  &:not(.toolbar_btn) {
    text-transform: uppercase;
  }

  &.btn-sm {
    font-size: 0.9em;
    padding: 10px 20px;
  }

  &.nostyle {
    background-color: transparent;
    border-style: none;
    padding: 0;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.cta_banner {
  display: flex;
  flex-direction: column;

  > * {
    margin: 10px 0;
  }

  @media (min-width: $screen-lg-min) {
    background: $color1;
    color: $color-white;
    padding: 30px 40px;

    .btn {
      color: white !important;

      &:hover {
        background-color: rgba(white, 0.1) !important;
      }
    }
  }
}

.ui-tooltip-custom {
  border: 1px solid $border-color;
  padding: 4px 8px;
  pointer-events: none;
  position: absolute;
  z-index: 9999999;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  #content input[type='file'] {
    width: auto !important;
  }
}

@supports (-ms-accelerator: true) {
  #content input[type='file'] {
    width: auto !important;
  }
}
