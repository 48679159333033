body {
  color: $font-color;
}

a {
  color: $font-color;

  &:hover {
    color: $color4;
  }
}

form fieldset {
  background-color: rgba(255, 255, 255, 0.6);
}

.ui-tooltip-custom {
  background-color: #000;
  color: $color-white;
}

.subnav ul li a,
.designer_pick h3 {
  color: $color2;
}

#loading {
  background-color: rgba(0, 0, 0, 0.3);
}

#upload_scene .upload_scene_button_wrapper.open,
#upload_scene .upload_scene_button_wrapper.open + span {
  background-color: $color-white;
}

.btn.disabled:hover,
.btn.disabled:active,
.grid_cell .grid_delete_button,
.combobox-textfield,
.grid_cell.saved_design_report > .grid_swatch + i.fa,
span.pin,
.message,
#splash .orspan {
  color: $color-white;
}

header nav a {
  color: $color-white;
}

.message {
  background-color: $color-white;
  color: $font-color-light;
}

#header {
  background-color: $color1;
  color: $color-white;
}

.btn {
  background-color: $color1;
  color: $color-white;

  &:not(.disabled) {
    &:hover,
    &:active,
    &.active {
      background-color: $color2;
      color: $color-white; // for <a>s
    }
  }

  &.btn-outline {
    background-color: transparent;
    border: 1px solid currentColor;
    color: $color1;

    &:not(.disabled) {
      &:hover,
      &:active,
      &.active {
        background-color: rgba($color1, 0.1);
        color: $color1; // to override normal styling hover override
      }
    }
  }

  &.nostyle {
    color: $font-color;
    font-weight: bold;
    text-decoration: underline;
    transition: none;

    &:not(.disabled) {
      &:hover,
      &:active,
      &.active {
        background-color: transparent;
        color: $color4;
      }
    }
  }
}

header nav ul li a:hover,
header nav ul li a:active,
header nav ul li div:hover,
header nav ul li div:active,
header nav ul li div.active,
.message.error {
  color: $color4;
}

.page {
  background-color: $color-white;
  color: $font-color;
}

.dialog {
  background-color: rgba(0, 0, 0, 0.75);

  > * {
    background-color: $color-white;
  }
}

.facebook:hover {
  color: #3b5998;
}

.twitter:hover {
  color: #55acee;
}

.pinterest:hover {
  color: #cb2027;
}

#share_design .envelope:hover {
  color: #fc0;
}

.color-success {
  color: #31930d;
}

.color-error {
  color: #d50000;
}

/* -- Mobile Exceptions -- */
@media (max-width: $screen-md-max) {
  header nav ul {
    background-color: #333;
  }

  header nav a {
    color: $color-white;
  }
}
