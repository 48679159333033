#trim_product_paths .grid_cell.products label,
#window_trim .grid_accordion.windowtrim .grid_cell.products label {
  text-overflow: clip;
  white-space: normal;
}

/* -- Defaults -- */
.grid_cell {
  display: block;
  float: left;
  padding: 4px;
  position: relative;
  text-align: center;
  width: 50%;

  &.selected {
    .grid_swatch {
      outline: 4px solid $border-color;
    }
  }

  label {
    padding-top: 5px;
  }

  .grid_swatch {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    outline: 4px solid transparent;
    padding-top: 100%;
    position: relative;
    width: 100%;

    &:hover,
    &.selected {
      outline: 4px solid $border-color;
    }
  }

  .grid_delete_button {
    border-bottom-right-radius: 4px;
    cursor: pointer;
    display: block;
    left: 6px;
    padding: 6px;
    position: absolute;
    top: 6px;
  }

  /* -- Custom Rules -- */

  &.products {
    margin-bottom: $product-menu-padding;
    outline: 4px solid transparent;
    padding: 8px;

    .grid_swatch {
      padding-top: 70%;
    }

    label {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.window_style,
    &.shutter_style,
    &.door_style {
      .grid_swatch {
        background-size: contain;
      }
    }

    &.siding_color,
    &.trim_color,
    &.masonry_color,
    &.door_color,
    &.window_color,
    &.shutter_color,
    &.roof_color {
      .grid_swatch {
        border: 1px solid rgb(200, 200, 200);
        padding-top: 100%;
      }
    }
  }

  &.checkbox,
  &.apply_to_cell {
    font-size: 0.9em;
    padding: 5px 0;
    text-align: left;
    width: 50%;

    input[type='checkbox'] {
      display: none;

      &:checked + label span {
        background-color: $color2;
        opacity: 1 !important;
      }

      &.has_product + label span {
        background-color: $color2;
        opacity: 0.5;
      }

      + label {
        cursor: pointer;
        display: inline-block;
        padding-left: 20px;
        text-indent: -20px;

        span {
          background-color: $color-white;
          border: 1px solid $border-color;
          cursor: pointer;
          display: inline-block;
          height: 16px;
          margin: -2px 10px 0 0;
          vertical-align: middle;
          width: 16px;
        }
      }
    }

    label {
      text-transform: capitalize;
    }
  }

  &.saved_design,
  &.sample_project,
  &.saved_upload,
  &.saved_design_report {
    width: 25%;

    > .grid_swatch {
      border: 2px solid $color-white;
      outline: 1px solid $border-color;
      padding-top: 50%;

      label {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        bottom: 12px;
        padding: 4px 8px;
        position: absolute;
        right: 0;
      }
    }
  }

  &.complementary_combination {
    width: 50%;

    .grid_swatch {
      padding-top: 0;
    }
  }

  &.profile_combination {
    width: 50%;

    .grid_swatch {
      padding-top: 65%;
    }
  }

  &.base_color_swatch {
    width: 14.28%;
  }

  &.main_siding_profile {
    width: 33%;
  }

  /* -- Design report stuff -- */

  &.saved_design_report {
    &.selected {
      i {
        &.fa-check {
          display: block;
        }
      }
    }

    i {
      &.fa-check {
        display: none;
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }

    > .grid_swatch {
      + i {
        &.fa {
          font-size: 3em;
          text-shadow: #222 0 1px 6px;
        }
      }
    }
  }
}

@media (max-width: $screen-md-max) {
  .grid_cell {
    width: 25%;

    &.saved_design,
    &.sample_project,
    &.saved_upload,
    &.saved_design_report {
      width: 100%;
    }
  }
}

@media (max-width: $screen-sm-max) {
  .grid_cell {
    width: 33.33%;
  }
}

@media (max-width: $screen-xs-max) {
  .grid_cell {
    width: 50%;
  }
}
