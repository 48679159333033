#before_after.dialog {
  > .section {
    overflow: hidden;

    .main {
      height: 100%;
      overflow: auto;
      padding: 0; // even on mobile
    }
  }

  .page {
    background: white;
    border: 1px $border-color solid;
    box-shadow: 0 0 10px #ccc;
    margin: 1cm auto;
    min-height: 23.7cm;
    padding: 1cm;
    width: 21cm;
  }

  .page.pdf {
    border: none;
    box-shadow: none;
    height: 28.7cm;
    margin: 0 auto;
    width: 216mm;
  }

  /**
   * These css rules are to fix issues caused by the html to pdf converter.
   * These push the footer to the bottom for each page
   */
  .page.pdf:first-of-type .subpage {
    height: 233mm;
  }

  .page.pdf.windows .subpage {
    height: 242mm;
  }

  .page.pdf.siding .subpage {
    height: 246mm;
  }

  .page.pdf.other .subpage {
    height: 250mm;
  }

  .page.pdf.overflow .subpage {
    height: 246mm;
  }

  /* */

  .page:not(:last-of-type) {
    page-break-after: always;
  }

  .subpage {
    height: 220mm;
    padding-bottom: 13mm;
    padding-left: 1.6em;
    padding-right: 1.6em;
    position: relative;
  }

  h2 {
    font-size: 1.2em;
    font-weight: normal;
    padding-top: 0.6em;
    text-transform: uppercase;
  }

  h3 {
    font-size: 10pt;
    font-weight: bold;
    margin: 0;
  }

  img {
    border: 0;
    text-decoration: none;
  }

  .green {
    color: $accent-color;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .header {
    align-items: center;
    background-color: $color1;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
    position: relative;
    -webkit-print-color-adjust: exact;

    > * {
      margin: 10px 20px;
    }

    .title {
      color: $color-white;
      font-size: 18px;
      font-weight: 400;
      text-align: right;
      text-transform: uppercase;

      p {
        margin: 0;
      }
    }
  }

  /********* FIRST PAGE ************/

  .after_image {
    margin-top: 30px;
    position: relative;
  }

  .fullsize {
    object-fit: cover;
    width: 100%;
  }

  .original_house {
    margin-top: 2em;
  }

  .before_image {
    float: left;
    width: 60%;
  }

  .before_image img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  .project_details {
    float: right;
    padding-left: 1.8em;
    width: 40%;
  }

  .project_details > div:not(:first-child) {
    margin-top: 1em;
  }

  .project_details > div span {
    display: block;
    line-height: 1.4em;
  }

  .project_details > div .title {
    text-transform: uppercase;
  }

  .project_details > div .info {
    color: $color1;
    font-weight: 600;
  }

  .project_details > p {
    font-size: 0.8em;
    margin-top: 1.4em;
  }

  .project_details > p span {
    text-transform: uppercase;
  }

  /************ DISCLAIMER *************/

  .disclaimer {
    border-top: 2px solid $border-color;
    padding-top: 0.6em;
  }

  .disclaimer h4 {
    font-size: 0.7em;
  }

  .disclaimer p {
    font-size: 0.6em;
  }

  /******** SUMMARY *********/

  .summary_item {
    border-bottom: 2px solid $border-color;
    margin: 1em 0;
    position: relative;
  }

  .summary_item .summary_info {
    float: left;
    padding-bottom: 3em;
    width: 120px;
  }

  .summary_item .summary_info h3 {
    color: $accent-color;
    font-weight: 400;
    margin-bottom: 0.75em;
    padding-top: 0.45em;
    text-transform: uppercase;
  }

  .summary_item .summary_info .summary_swatch {
    height: 120px;
    margin-bottom: 0.5em;
    object-fit: cover;
    width: 100%;
  }

  .summary_item .summary_info h4 {
    font-weight: 400;
  }

  .summary_item .summary_info h4 {
    font-size: 0.7em;
    font-weight: 400;
  }

  .summary_item .summary_table {
    left: 135px;
    position: absolute;
    right: 0;
  }

  .summary_item .summary_table table {
    border-collapse: collapse;
    width: 100%;
  }

  .summary_item .summary_table table thead th:nth-child(1) {
    color: $font-color;
    font-size: 0.65em;
    text-transform: uppercase;
  }

  .summary_item .summary_table table tbody tr:last-child td {
    border-bottom-style: none;
  }

  .summary_item .summary_table table tr th:first-child,
  .summary_item .summary_table table tr td:first-child {
    padding-right: 0.6em;
    width: 40%;
  }

  .summary_item .summary_table table tr th,
  .summary_item .summary_table table tr td {
    border-bottom: 2px solid $border-color;
    font-size: 0.7em;
    padding: 0.75em 0;
    text-align: left;
    width: 20%;
  }

  .summary_item::after {
    clear: both;
    content: '';
    display: block;
    height: 0;
    visibility: hidden;
  }

  /********* PRODUCT SWATCHES **********/

  .grid_item {
    float: left;
    margin-right: 2%;
    margin-top: 1.2em;
    width: 31%;
  }

  .grid_item.window {
    width: 18%;
  }

  .grid_item img {
    height: 180px;
    object-fit: contain;
    object-position: 0;
    width: 100%;
  }

  .grid_item.window h3 {
    display: none;
  }

  .grid_item h3 {
    font-size: 1em;
    font-weight: normal;
    padding-bottom: 1em;
  }

  .grid_item p:first-child {
    padding-top: 0.5em;
  }
}
