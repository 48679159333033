#privacy_policy.dialog {
  .section {
    display: flex;
    flex-direction: column;
    padding: 40px;
  }

  .accordion {
    flex: 1;

    h3 {
      border-top: solid 1px $border-color;
      cursor: pointer;
      margin-top: 10px;
      outline: none;
      padding-top: 10px;

      &:hover {
        color: $color2;
      }
    }

    > div {
      border-bottom: none;
    }
  }
}
