#sign_in.dialog .section,
#sign_up.dialog .section,
#reset_password.dialog .section,
#reset_password_form.dialog .section {
  display: flex;
  flex-direction: column;
  padding: 0;
  width: 100%;

  .title {
    margin-top: 50px;
    text-align: center;
  }

  .dialog_close {
    padding: 10px 5px;
  }

  form {
    display: flex;
    flex-direction: column;
    padding: 30px;
  }

  hr {
    border: 0;
    border-top: 1px solid $border-color;
    height: 0;
    margin: 20px 0;
  }

  a {
    align-self: center;

    &.nostyle {
      color: $color1;

      &:hover {
        color: $color2;
      }
    }
  }

  p {
    &.small {
      font-size: small;
      line-height: 1;
      margin-bottom: 10px;
      margin-top: 40px;
    }
  }

  .login_btn_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .button {
      margin: 15px 0;
    }
  }

  .footer {
    align-items: center;
    background-color: $gray-lighter;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    text-align: center;
  }
}

#reset_password .section {
  form {
    min-height: 250px;
  }
}

#sign_in .section {
  form {
    min-height: 210px;
  }
}

#sign_up.dialog .section {
  .header {
    margin-bottom: 30px;

    .title,
    p {
      font-weight: normal;
      text-align: left;
    }
  }

  .container {
    display: flex;
    overflow-y: auto;

    > * {
      display: flex;
      flex: 1 0 50%;
      flex-direction: column;
      padding: 0 30px 30px;

      > * {
        flex-shrink: 0;

        &:first-child {
          margin-top: auto;
        }

        &:last-child {
          margin-bottom: auto;
        }
      }

      &:not(:first-child) {
        @media (max-width: $screen-sm-max) {
          border-top: 1px solid $border-color;
        }

        @media (min-width: $screen-md-min) {
          border-left: 1px solid $border-color;
        }
      }

      @media (min-width: $screen-md-min) {
        overflow-y: auto;
      }
    }

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }
  }

  form {
    padding: 0;

    .checkbox_container,
    .radio_container {
      display: flex;
      margin-top: 10px;

      input {
        margin-right: 5px;
      }
    }

    .radio_container {
      flex-wrap: wrap;

      .radio-group {
        margin-left: 15px;
      }
    }

    .btn {
      align-self: flex-start;
      margin-top: 30px;
    }
  }

  .guests-allowed {
    // hide guest section unless continuing to sample projects gallery
    body:not([data-lastsavestep='select_scene']) & {
      display: none;
    }
  }

  [data-lastsavestep='select_scene'] & {
    @media (min-width: $screen-md-min) {
      max-width: 400px * 2 !important; // override generic responsive sizing helper
    }
  }
}

#reset_password.dialog .section,
#reset_password_form.dialog .section {
  input {
    &:first-of-type {
      margin-top: 20px !important;
    }

    &:last-of-type {
      margin-bottom: 20px !important;
    }
  }
}

#my_account.page {
  .main,
  .sidebar {
    .section {
      padding: 0;

      fieldset {
        background-color: transparent;

        > label {
          cursor: pointer;
          display: block;
          float: left;
          margin-bottom: 0.75em;
          position: relative;
          width: 100%;

          span:first-of-type {
            display: block;
            margin-bottom: 0.25em;
          }

          input:not([type='checkbox']):not([type='radio']) {
            width: 100%;
          }
        }

        .footer {
          clear: both;
          padding: 0;
        }
      }

      .half {
        float: none;
        width: 100%;
      }
    }
  }

  .main {
    h2.subtitle {
      font-size: 1.25em;

      .first_name {
        text-transform: capitalize;
      }

      small {
        color: $font-color-light;
        font-size: 0.75em;

        a {
          color: inherit;
          text-decoration: underline;
        }
      }
    }

    .section {
      fieldset {
        > label {
          float: left;
          padding-right: 2em;
          width: 50%;
        }

        .footer {
          padding-top: 20px;
        }
      }
    }
  }

  @media (max-width: $screen-md-max) {
    .sidebar,
    .main {
      bottom: auto;
      height: auto;
      left: auto;
      padding: 20px;
      position: relative;
      right: auto;
      top: auto;
      width: auto;
    }

    .main {
      .section {
        fieldset {
          label {
            float: none;
            padding: 0;
            width: 100%;
          }
        }
      }
    }

    .sidebar {
      .section {
        fieldset {
          label {
            padding: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
