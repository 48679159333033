#project-preview.dialog {
  $dialog-width: 1000px;
  $dialog-height: 600px;

  > * {
    background: none;
    height: auto;
    width: auto;
  }

  > .section {
    overflow: hidden;

    .main {
      background-color: $color-white;
      display: flex;
      height: 100%;
      justify-content: center;
      overflow: auto;
      padding: 0; // even on mobile
      position: relative;

      .before-after-container {
        cursor: ew-resize;
        height: $dialog-height;
        margin: 50px;
        overflow: hidden;
        position: relative;
        width: $dialog-width;

        .img {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          display: block;
          height: 100%;
          overflow: hidden;
          position: relative;
          width: auto;

          &.after {
            transform: translateX(-50%);
          }

          .label {
            bottom: 0;
            color: $color-white;
            font-size: 16px;
            margin: 25px;
            position: absolute;
            user-select: none;

            &.before {
              left: 0;
            }

            &.after {
              right: 0;
            }
          }
        }

        .after-wrapper {
          bottom: 0;
          left: 0;
          overflow: hidden;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateX(50%);
        }

        .separator {
          background: $border-color;
          height: 100%;
          left: 50%;
          pointer-events: none;
          position: absolute;
          top: 0;
          user-select: none;
          width: 2px;
        }

        .slider {
          @include centerAbsoluteElement;

          align-items: center;
          background-color: $color-white;
          border-radius: 50%;
          box-shadow: 0 0 12px rgba(51, 51, 51, 0.5);
          cursor: pointer;
          display: flex;
          height: 55px;
          justify-content: center;
          pointer-events: none;
          position: absolute;
          user-select: none;
          width: 55px;
          z-index: 2;

          i {
            color: $color1;
            font-size: 14px;

            &:nth-child(1) {
              margin-right: 5px;
            }

            &:nth-child(2) {
              margin-left: 5px;
            }
          }
        }
      }
    }

    .disclaimer {
      color: $font-color-light;
      margin-top: 15px;

      h4,
      p {
        font-size: 12px;
      }
    }

    @media (max-width: $screen-sm-max) {
      padding: 10px;

      .main .before-after-container {
        height: $dialog-height / 2;
        margin: 35px;
      }
    }
  }

  .close_icon {
    color: $color1;
  }
}
