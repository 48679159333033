#upload_status.page {
  .main {
    display: flex;
    flex-direction: column;

    > section {
      padding-top: 40px;

      > header {
        color: $color1;
        margin-bottom: 10px;
      }

      .half {
        margin-bottom: 40px;

        .cta_banner {
          margin-top: 40px;
        }

        @media (max-width: $screen-xs-max) {
          width: 100%;

          .cta_banner {
            padding-top: 40px;
            border-top: solid 2px $color1;
          }
        }

        @media (min-width: $screen-sm-min) {
          &:first-child {
            padding-right: 10%;
          }
        }
      }

      &:not(.hidden) + section {
        border-top: solid 2px $color1;
      }
    }

    #included_ds {
      .uploads {
        margin: 10px 0;
        line-height: 1.5;

        li {
          font-weight: bold;

          i {
            width: 1.5em;
          }
        }
      }
    }

    #included_ai {
      p {
        a {
          color: $color1;
          text-decoration: underline;
        }
      }

      .uploads {
        margin-top: 20px;
      }
    }
  }
}

.upload_status_card {
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  > figure {
    width: 40%;
    margin-right: 20px;

    img {
      max-width: 100%;
      vertical-align: middle;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    flex: auto;

    > header {
      font-weight: bold;
      margin-top: 5px;
    }

    > div {
      margin: 10px 0;
      flex: auto;

      progress {
        width: 100%;
      }
    }
  }

  &[data-status="processing"] {
    .for_success,
    .for_error {
      &:not(.for_in_progress) {
        display: none;
      }
    }
  }

  &[data-status="success"] {
    .for_in_progress,
    .for_error {
      &:not(.for_success) {
        display: none;
      }
    }
  }

  &[data-status="error"] {
    .for_in_progress,
    .for_success {
      &:not(.for_error) {
        display: none;
      }
    }
  }
}
