@mixin clear($height: 0) {
  clear: both;
  content: '';
  display: block;
  height: $height;
  visibility: hidden;
}

@mixin your-projects-swatch {
  background-color: #eee;
  box-shadow: rgba(0, 0, 0, 0.05) 5px 5px 0;
  margin-bottom: 1em;
  transition: $default-transition;
}

@mixin centerAbsoluteElement {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

// safari work around
// src: s3 codebase
@mixin safariOnly(
  $rules,
) {
  @media not all and (min-resolution: 0.001dpcm) {
    @each $property, $value in $rules {
      #{$property}: $value;
    }
  }
}
