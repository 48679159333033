.overlay-spinner {
  background: rgba(0, 0, 0, 0.39);
  height: 100%;
  position: relative;
  transition: transform 350ms ease-in-out;
  width: 100%;

  .spinner {
    $size: 40px;
    // Adopted from: https://github.com/tobiasahlin/SpinKit#bounce

    @include centerAbsoluteElement();

    display: block !important; // Force display the element
    height: $size;
    position: absolute;
    width: $size;

    .bounce {
      animation: sk-bounce 2s infinite ease-in-out;
      background-color: $color2;
      border-radius: 50%;
      height: 100%;
      left: 0;
      opacity: 0.6;
      position: absolute;
      top: 0;
      width: 100%;
    }

    .bounce:nth-child(2) {
      animation-delay: -1s;
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
    }

    50% {
      transform: scale(1);
    }
  }
}
