$gray-lighter: #f6f6f6;
$project-toolbar-height-mobile: 45px;

@mixin elevate() {
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
}

body {
  &.isStreetViewProject {
    .notForStreetViewProject {
      display: none !important;
    }
  }
}

.project_sidebar {
  background-color: $gray-lighter;
  bottom: 0;
  display: flex;
  flex-direction: column;
  left: 0;
  position: absolute;
  top: $project-toolbar-height;
  transform: translateX(-100%);
  transition: all 350ms ease-in-out;
  z-index: 3;

  .scroll-container {
    flex: auto;
    overflow-y: auto;
  }

  .content-group {
    background-color: $color-white;
    padding-bottom: $product-menu-padding / 2;
    padding-top: $product-menu-padding / 2;
  }

  .breadcrumbs {
    border-bottom: 1px solid $border-color;
    padding: 20px $product-menu-padding $product-menu-padding / 2;

    a {
      text-decoration: underline;
    }

    i {
      font-size: 0.67em;
    }

    span {
      color: $color1;
      font-weight: bold;
    }
  }

  &.open {
    @include elevate();

    transform: translateX(0);
  }

  @media (max-width: $screen-md-max) {
    right: 0;
    top: $project-toolbar-height-mobile;
  }

  @media (min-width: $screen-lg-min) {
    border-right: 1px solid $border-color;
    width: $product-menu-width;

    &.submenu {
      padding-bottom: $product-menu-padding / 2;
      padding-top: $product-menu-padding / 2;
      top: $project-toolbar-height;
      transform: translateX(-$product-submenu-width-dense);
      width: $product-submenu-width-dense;
      z-index: 1;

      @media (min-width: $screen-xl-min) {
        transform: translateX(-$product-submenu-width);
        width: $product-submenu-width;
      }

      .breadcrumbs {
        display: none;
      }

      &.open {
        transform: translateX($product-menu-width);
      }
    }
  }
}

#project_view.page {
  overflow: hidden;

  > .main {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    padding: 0;

    .project_toolbar {
      display: flex;
      height: $project-toolbar-height;
      width: 100%;

      .toolbar_btn {
        align-items: center;
        background: none;
        border-right: solid 1px $border-color;
        color: $color1;
        display: flex;
        flex: auto;
        font-weight: bold;
        height: 100%;
        justify-content: center;
        padding: 0 10px;
        white-space: pre-line;

        .icon,
        i {
          font-size: 20px;
          margin-right: 10px;
        }

        &:not(.disabled):hover {
          color: $color2;
        }
      }

      .product_menu_btn {
        width: 100%;

        h2 {
          flex: auto;
          font-size: inherit;
          font-weight: bold;
          text-align: left;
        }

        // stack x on top of chevron so it can morph into it on mobile
        i {
          display: inline-block;
          font-size: inherit;
          margin: 0;
          transition: all 0.2s ease-in-out;
          width: 25px;

          + i {
            color: inherit;
            margin-left: -25px;
          }
        }

        &.open {
          i {
            transform: rotate(180deg);
          }
        }
      }

      .header {
        min-width: $product-menu-width;
      }

      .body {
        display: flex;
        flex: auto;
      }

      #finish_menu {
        font-weight: bold;
        min-width: $product-menu-width;

        .menu {
          @include elevate();

          background-color: $color-white;
          display: none;
          min-height: 0;
          position: absolute;
          text-align: left;
          z-index: 1;

          li {
            align-items: center;
            cursor: pointer;
            display: flex;
            font-size: 0.8em;
            min-height: 40px;
            padding: 0 15px;

            .icon {
              margin-right: 14px;
              margin-top: 2px;
              position: relative;
              width: 20px;

              &.save::before {
                content: url('../img/icon_save.png');
              }

              &.download_render::before {
                content: url('../img/icon_download_render.png');
              }

              &.download::before {
                content: url('../img/icon_download.png');
              }

              &.email::before {
                content: url('../img/icon_email.png');
              }

              &.share::before {
                content: url('../img/icon_share.png');
              }

              &.find::before {
                content: url('../img/icon_find.png');
              }
            }

            &:hover {
              text-decoration: underline;
            }
          }
        }

        &.open {
          .menu {
            display: block;
          }
        }
      }
    }

    .project_image {
      flex: 1 1 auto;
    }

    .product_list {
      padding: 0;
    }

    .section-header {
      align-items: center;
      border-bottom: 1px solid $border-color;
      cursor: pointer;
      display: flex;
      font-size: inherit;
      justify-content: space-between;
      margin: 0;
      padding: $product-menu-padding / 2 $product-menu-padding;
      width: 100%;

      span {
        display: inline-block;
        margin-bottom: -0.25em; // @HACK: 'vertically center' non-descender-having text
      }

      i {
        padding: 0 5px;
        transition: transform 0.2s ease-in-out;
      }

      &.open {
        color: $color1;

        &.opens-slideout {
          position: relative;

          &::after {
            border-right: 6px solid $color1;
            bottom: 0;
            content: '';
            display: block;
            position: absolute;
            right: 0;
            top: 0;
          }
        }

        i {
          transform: rotate(180deg);
        }
      }
    }

    .section-header,
    .section-body {
      &.no-products {
        display: none !important;
      }
    }

    h2.section-header {
      color: $color1;
      font-weight: bold;
    }

    h3.section-header {
      font-weight: normal;

      &,
      + .section-body {
        padding-left: 0;
        padding-right: 0;
      }
    }

    h4.section-header {
      color: lighten(#595959, 25%);
      font-size: 13px;
      font-weight: lighter;
      letter-spacing: 0.1em;
      padding: $product-menu-padding $product-menu-padding 10px;
      text-transform: uppercase;
    }

    .section-body {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      height: 0;
      opacity: 0;
      overflow: hidden;
      transition: all 0.2s ease-in-out;
      visibility: hidden;

      &.product-container {
        display: none;
        height: 100%;
      }

      .product_utilities {
        border-bottom: 1px solid $border-color;
        display: flex;
        flex-direction: column;
        padding: $product-menu-padding / 2 $product-menu-padding;

        .product_buttons {
          $container-height: 40px;

          align-items: center;
          display: flex;
          justify-content: space-between;
          margin: 15px 0;
          max-height: $container-height;
          position: relative;
          top: unset !important;
          width: 100%;

          > div {
            .fa {
              color: $border-color;
              cursor: pointer;
              font-size: 1.8em;
              transition: all 0.2s;

              &.selected,
              &:hover {
                color: $color3;
              }
            }

            &.btn {
              align-items: center;
              display: flex;
              height: $container-height;
            }
          }

          .searchbox {
            display: flex;
            height: $container-height;
            margin-right: 15px;
            width: 100%;

            input {
              border-bottom-right-radius: 0;
              border-right: none;
              border-top-right-radius: 0;
              padding-right: $container-height;
              width: 100%;
            }

            .btn {
              border-bottom-left-radius: 0;
              border-top-left-radius: 0;
              padding: 0;
              width: $container-height;

              &.clear {
                background: none;
                color: inherit;
                margin-left: -$container-height;
                margin-right: 1px;
              }
            }
          }

          .btn.filters {
            span {
              line-height: 1;
            }

            .count {
              &:not(:empty)::before {
                content: '•';
                padding: 0.5em;
              }
            }
          }
        }
      }

      .accordion,
      .apply-to-container {
        display: flex;
        flex-direction: column;
        padding: 0 $product-menu-padding;

        > div {
          border-bottom: 0;
        }
      }

      .btn.apply {
        margin: $product-menu-padding / 2 0;
        width: 100%;
      }

      > .section-header {
        padding-top: $product-menu-padding;
      }

      div[class*='color'],
      &.search_results,
      &.filter_results,
      &.product_colors {
        .grid_cell {
          width: 20%;

          label {
            // to prevent text from clipping
            overflow: visible;
            // Help prevent long color names overflowing and covering other names
            font-size: 12px;
          }

          @media (max-width: $screen-xs-max),
            (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
            width: 25%;
          }

          @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            width: 12.5%;
          }
        }
      }

      &.grid {
        flex-direction: row;
        flex-wrap: wrap;
      }

      &.open {
        display: flex;
        height: 100%;
        opacity: 1;
        visibility: visible;

        // trim is an exception, because it contains sub categories
        &.has-subsections {
          height: auto;
        }

        &.grid {
          padding-top: $product-menu-padding;
        }
      }
    }

    .order_sample_cta {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: underline;

        img {
          max-width: 33%;
          z-index: 1;
          margin-right: -30px;
        }

        span {
          background-color: $color-white;
          color: $font-color;
          font-weight: bold;
          padding: 20px;
          padding-left: 40px;
          border: solid 3px $font-color-light;
          white-space: nowrap;
        }
      }
    }

    .designer_picks_cta {
      align-items: center;
      background-color: $color1;
      color: $color-white;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      justify-content: center;
      margin-top: auto;
      min-height: $project-toolbar-height;
      padding: 20px;

      h3,
      a {
        font-size: 15px;
      }

      h3 {
        color: inherit;
        margin: 5px 0;
      }

      .btn {
        background: none;
        font-weight: normal;
        padding: 0;
        text-decoration: underline;
      }
    }

    .zoom_buttons {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      position: fixed;
      bottom: 0;
      right: 0;
      padding: 15px;

      .btn {
        align-items: center;
        background-color: $color-white;
        border-radius: 50%;
        color: $color1;
        display: flex;
        font-size: 20px;
        height: 40px;
        justify-content: center;
        opacity: 0.8;
        padding: 0;
        position: relative;
        width: 40px;

        &:hover {
          opacity: 1;
        }

        &.disabled {
          opacity: 0.4 !important;
        }

        &.zoom_in {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;

          &::after {
            border-bottom: solid 1px $border-color;
            bottom: 0;
            content: '';
            position: absolute;
            width: 80%;
          }
        }

        &.zoom_out {
          border-top-left-radius: 0;
          border-top-right-radius: 0;
        }

        &.expand_contain {
          margin-top: 10px;
        }
      }

      #project_flags_badge {
        font-size: 14px;
        font-weight: bold;
        margin-top: 15px;
        margin-right: -15px;
        margin-bottom: -15px;

        a {
          color: inherit;

          &:not(:hover) {
            text-decoration: underline;
          }
        }

        > * {
          padding: 15px;

          &.for_ds {
            background-color: $color1;
            color: $color-white;
          }
          &.for_ai {
            background-color: $color-white;
            color: $color1;
          }
        }
      }
    }

    #product_menu {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
    }

    #product_submenu {
      .siding_configurator {
        &:not(.hasFilterResults) {
          .accordion > .filter_results {
            display: none;
          }
        }

        &:not(.hasSearchResults) {
          &.hasFilterResults {
            .accordion > *:not(.filter_results) {
              display: none;
            }
          }

          .searchbox .clear,
          .accordion.search_results {
            display: none;
          }
        }

        &.hasSearchResults {
          .accordion:not(.search_results) {
            display: none;
          }

          .btn.filters {
            opacity: 0.5;
            pointer-events: none;
          }
        }
      }

      .trim_configurator {
        padding-left: 0;
        padding-right: 0;
      }
    }

    @media (max-width: $screen-sm-max) {
      .breadcrumbs,
      .product_utilities,
      .accordion,
      .apply-to-container,
      h4.section-header {
        padding-left: $product-menu-padding / 2 !important;
        padding-right: $product-menu-padding / 2 !important;
      }

      .section-body {
        &.filter_results,
        &.product_colors {
          .grid_cell {
            width: 33%;
          }
        }
      }

      #product_submenu {
        .section-body[data-product].open {
          overflow-y: auto;
        }
      }

      .designer_picks_cta {
        h3 {
          display: none;
        }
      }
    }

    @media (max-width: $screen-sm-max), (min-width: $screen-lg-min) and (max-width: 1268px) {
      // @HACK: avoid text wrapping to three lines and overflowing on tiny and medium sized screens
      .project_toolbar {
        .toolbar_btn {
          span {
            display: none;
          }
        }
      }
    }

    @media (max-width: $screen-md-max) {
      .project_image {
        bottom: $project-toolbar-height * 2;
        left: 0;
        position: absolute;
        right: 0;
        top: $project-toolbar-height-mobile;
      }

      .project_toolbar {
        bottom: 0;
        flex-direction: column;
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;

        .btn,
        .menu {
          pointer-events: auto;
        }

        > * {
          background-color: $color-white;
          height: $project-toolbar-height;
        }

        .header {
          @include elevate();

          height: $project-toolbar-height-mobile;
          position: relative;
          z-index: 4;
        }

        .body {
          @include elevate();

          flex: none;
          margin-top: auto;
        }

        .product_menu_btn {
          // transform chevron into x when open
          i + i {
            opacity: 0;
          }

          &.open {
            i {
              opacity: 0;

              + i {
                opacity: 1;
              }
            }
          }
        }

        #finish_menu {
          > .btn {
            border-radius: 0;
            height: 100%;
            text-decoration: underline;
            width: 100%;
          }

          .menu {
            bottom: $project-toolbar-height;
            left: 0;
            right: 0;
          }
        }
      }

      .zoom_buttons {
        bottom: $project-toolbar-height * 2;
      }
    }

    @media (min-width: $screen-lg-min) {
      .project_toolbar {
        @include elevate();

        background-color: $color-white;
        z-index: 4;

        .product_menu_btn {
          padding-left: $product-menu-padding;
          padding-right: $product-menu-padding;

          i + i {
            display: none;
          }
        }

        #finish_menu {
          display: flex;
          justify-content: center;

          .btn {
            margin: 10px;
            padding: 0;
            width: 100%;
          }

          .menu {
            min-width: $product-menu-width;
            right: 0;
            top: $project-toolbar-height;
          }
        }
      }

      #product_submenu {
        .section-body[data-product].open {
          overflow-y: auto;
        }
      }

      #project_image {
        &.open {
          .overlay-spinner .spinner {
            margin-left: $product-menu-width / 2;
          }
        }

        &.open-submenu {
          .overlay-spinner .spinner {
            margin-left: ($product-menu-width + $product-submenu-width-dense) / 2;

            @media (min-width: $screen-xl-min) {
              margin-left: ($product-menu-width + $product-submenu-width) / 2;
            }
          }
        }
      }
    }
  }
}

#save_design,
#your_projects_choose_folder {
  select,
  input {
    width: 100%;
  }
}
