#splash.page {
  background-color: transparent;
  display: block; // always show

  .dialog_close {
    display: none;
  }

  .split-columns {
    .section {
      display: grid;
      display: -ms-grid;
      grid-auto-rows: 1fr;
      -ms-grid-rows: 1fr 1fr 1fr;
      padding: 5em;

      .section-container {
        display: grid;
        display: -ms-grid;
        grid-auto-rows: 1fr;
        // needed for IE11 support
        -ms-grid-rows: 1fr 1fr 1fr;
      }

      .title {
        align-self: flex-end;
        -ms-grid-row-align: end;
        padding: 30px 0;
      }

      h1.title {
        color: $color1;
        font-size: 60px;
        font-weight: 900; // @TODO: use black font
        letter-spacing: -0.04em;
        line-height: (48 / 60);
      }

      .btn {
        align-self: start;
        -ms-grid-column-align: start;
        -ms-grid-row-align: start;
        justify-self: start;
        margin-top: 1em;
        min-width: 200px;
        padding: 20px;
      }

      &:first-child {
        background-color: rgba(255, 255, 255, 0.9);
        box-shadow: #000 4px 0 12px;
        flex: 6;

        .title {
          -ms-grid-column: 1;
          -ms-grid-row: 1;
        }

        p {
          -ms-grid-column: 1;
          -ms-grid-row: 2;
        }

        .btn {
          -ms-grid-column: 1;
          -ms-grid-row: 3;
        }
      }

      &:last-child {
        background-color: rgba(210, 210, 210, 0.9);
        flex: 4;

        .title {
          -ms-grid-column: 2;
          -ms-grid-row: 1;
        }

        p {
          -ms-grid-column: 2;
          -ms-grid-row: 2;
        }

        .btn {
          -ms-grid-column: 2;
          -ms-grid-row: 3;
        }
      }
    }
  }

  @media (max-width: $screen-md-max) {
    background: $color-white;

    .split-columns {
      flex-direction: column;

      .section {
        display: block;
        padding: 2em;
        width: 100%;

        h1.title {
          font-size: 3em;
          padding-top: 0;
        }

        p {
          font-size: 1em;
        }

        &:nth-child(1) {
          .section {
            border-bottom: 0.5px solid #8c8c8c;
          }
        }
      }
    }
  }
}

#choose_language {
  .title {
    margin-bottom: 20px;
  }

  select {
    width: 100%;
  }
}
