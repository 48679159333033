#product_filters.dialog {
  > * {
    height: auto;
    max-width: 600px;
  }

  > .section {
    padding: 0;

    .main {
      padding: 40px;

      .product-filters-container {
        display: flex;
        flex-direction: column;

        section {
          display: flex;
          flex-direction: column;

          h3 {
            color: $color1;
            font-weight: normal;
            text-transform: uppercase;
          }

          .filters {
            display: flex;
            margin: 15px 0;

            &.brands {
              flex-wrap: wrap;
            }

            &.colors {
              justify-content: space-between;

              .color_swatch_sm {
                $size: 40px;

                align-items: center;
                cursor: pointer;
                display: flex;
                height: $size;
                justify-content: center;
                transition: $default-transition;
                width: $size;

                i {
                  color: $color-white;
                  opacity: 0;
                }

                &.white {
                  border: 1px solid $color-grey;

                  i {
                    color: initial;
                  }
                }

                &.selected {
                  border-radius: 50%;

                  i {
                    opacity: 1;
                  }
                }
              }
            }
          }
        }

        .separator {
          background: $border-color;
          height: 2px;
          left: 50%;
          margin: 15px 0;
          pointer-events: none;
          user-select: none;
          width: 100%;
        }
      }

      @media (max-width: $screen-xs-max) {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    footer {
      @include elevate();

      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 15px;

      a {
        color: $color1;
        font-weight: normal;
      }
    }
  }
}
