#your_projects.page {
  bottom: 0;

  .sidebar {
    > .section {
      padding: 0;
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }

    h2 {
      font-weight: 600;
      margin-bottom: 1em;
    }

    ul {
      list-style: initial !important;

      li {
        display: list-item !important;
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
  }

  .main {
    h2.subtitle {
      color: $font-color;
      font-size: 1.25em;
      font-weight: 400;
      text-transform: initial;
      margin-bottom: 20px;

      .first_name {
        text-transform: capitalize;
      }

      small {
        color: $font-color-light;
        font-size: 0.75em;

        a {
          color: $font-color-light;
          text-decoration: underline;
        }
      }
    }

    .no_folders {
      padding: 1em 0;
    }

    .card_list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -10px;

      .refinements_no_results {
        margin: 20px 10px;
      }
    }

    .folder_tab_page {
      background-color: #f0f0f0;

      > header {
        &:first-child {
          display: flex;
          align-items: center;
          background-color: $color1;
          color: $color-white;
          padding: 5px 10px;
          cursor: pointer;

          .folder_name {
            display: inline-block;
            height: auto;
            font-size: 20px;
            padding: 0.4em 0.75em 0.25em;
            margin: 0;
          }

          input.folder_name {
            &[readonly],
            &:not([readonly]) + span {
              display: none !important;
            }
          }

          a {
            color: inherit;
            margin-left: 1em;
          }

          .rename_folder,
          .save_folder_name {
            &:hover {
              text-decoration: underline;
            }
          }

          .save_folder_name {
            display: none;
          }

          .close {
            position: relative;
            padding-left: 30px;
            margin-left: auto;

            .close_icon {
              display: inline-block;
              color: inherit;
            }
          }
        }

        &:not(:first-child) {
          border-bottom: 2px dashed $font-color;
          padding: 10px 0;
          margin: 0 15px;
        }
      }

      > .card_list {
        padding: 10px;
        margin: 0;
      }
    }

    .grid_swatch {
      min-width: 200px;
      min-height: 133px;
      background-color: $font-color-light;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      cursor: pointer;

      .badge {
        display: inline-block;
        text-transform: uppercase;
        vertical-align: top;
        padding: 8px;

        &.for_ai {
          background-color: $color-white;
          color: $color1;
          font-size: 10px;
        }

        &.for_ds {
          background-color: $color1;
          color: $color-white;
          font-size: 12px;
        }
      }

      .overlay {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        color: $color-white;
        text-align: center;
        padding: 10px;
      }
    }

    .folder {
      display: flex;
      flex-direction: column;
      min-width: calc(33% - 2 * 10px);
      background-color: #f0f0f0;
      padding: 10px;
      margin: 10px;

      a {
        &:not(:hover) {
          color: $color1;
        }
      }

      footer {
        display: flex;
        align-items: center;
        color: inherit;
        margin-top: 10px;

        .fa {
          font-size: 18px;
          color: inherit;
        }

        .folder_name {
          flex: auto;
          font-size: 15px;
          color: inherit;
        }
      }
    }

    .card_item {
      display: flex;
      width: 100%;
      padding: 10px;

      @media (min-width: $screen-md-min) {
        width: 50%;
      }

      > * {
        flex: 1 1 50%;
      }

      .grid_swatch {
        min-width: 0;
      }

      .project_info {
        display: flex;
        flex-direction: column;
        line-height: 1.5;
        font-size: 14px;
        padding: 0 20px;

        .project_last_saved {
          font-size: 12px;
          font-weight: normal;
          margin-bottom: 10px;
        }

        a:not(:hover) {
          color: $color1;
        }
      }

      &.disabled {
        .grid_swatch {
          pointer-events: none;
        }

        a {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .folder_tabs,
    .folder_tab_page,
    .folder_uncategorized,
    #tab-projects {
      margin-bottom: 20px;

      > header {
        display: flex;
        align-items: center;
        padding: 40px 0 10px;

        > *:first-child {
          flex: auto;

          a:not(:hover) {
            color: $color1;
          }
        }
      }
    }

    .folder_uncategorized {
      border-top: dashed 2px $font-color;
    }

    .card_list_refinements {
      select {
        font-size: 0.9em;
        padding-top: 0;
        padding-bottom: 0;
        border-radius: 0;
        border: 0;
        border-bottom: 1px solid $font-color;
        margin: 0 5px;
      }
    }
  }

  @media (max-width: $screen-md-max) {
    .sidebar {
      bottom: 0;
      left: auto;
      overflow: auto;
      padding: 20px 20px 0;
      right: 0;
      top: 0;
      width: 180px;

      > .section {
        transform: none;
      }
    }

    .main {
      bottom: 0;
      left: 0;
      overflow-x: hidden;
      overflow-y: auto;
      position: absolute;
      right: 180px;
      top: 0;
      width: auto;
    }
  }

  @media (orientation: portrait) {
    .main {
      right: 0;
    }

    .sidebar {
      display: none;
    }
  }
}
