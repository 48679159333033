body {
  font-family: $font-stack;
  font-size: 15px;
}

p {
  line-height: 1.5;
}

input,
select,
textarea {
  font-family: inherit;
  font-size: 1em;
}

h1 {
  font-size: 25px;
}

h2 {
  font-size: 25px;
  font-weight: normal;
  line-height: 1;
}
