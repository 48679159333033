#designer_picks_configurator_body {
  overflow-y: auto;

  .scroll-container {
    padding: $product-menu-padding;

    > p {
      margin-bottom: $product-menu-padding;
    }

    @media (max-width: $screen-md-max) {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}

.designer_pick {
  padding: $product-menu-padding / 2 0;

  .swatches {
    background-size: cover;
    display: flex;
    flex-wrap: wrap;
    height: 200px;
    position: relative;

    .sub {
      background-size: cover;
      height: 67px;
      margin-top: 133px;
      width: 50%;
    }

    .designer_pick_overlay {
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .info {
    margin-top: $product-menu-padding / 2;

    h3 {
      font-size: 1em;
    }
  }
}
