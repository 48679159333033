.material-tabs {
  > ul {
    display: flex;
    box-shadow: 0 4px 4px #00000033;

    > li {
      flex: auto;

      > a {
        display: block;
        text-align: center;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1.25px;
        padding: 15px 30px 12px;
        border-bottom: solid 2px transparent;
      }

      &.ui-state-active {
        > a {
          border-bottom-color: $color1;
        }
      }
    }
  }
}
