#scene_upgrade.dialog {
  .section {
    h1,
    h3 {
      margin-bottom: 10px;
    }

    textarea {
      width: 100%;
      margin: 10px 0;
    }

    .title {
      color: $color1;
    }

    .footer {
      text-align: center;
    }
  }
}
