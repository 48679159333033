.no_uploads {
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  top: 40%;
}

.not_masked {
  border-radius: 12px;
  cursor: pointer;
  display: none;
  padding: 2px 6px;
  position: absolute;
  right: 17px;
  text-align: center;
  top: 60px;
}

.not_masked i.fa {
  vertical-align: middle;
}

#select_scene {
  &.page {
    .main {
      .paginator {
        display: flex;
        flex-wrap: wrap;
        margin-left: -15px;
        margin-right: -15px;
      }

      .grid_cell {
        &.sample_project,
        &.upload_card {
          padding: 15px;
          width: 25%;

          button {
            font-weight: bold;

            &:hover {
              text-decoration: underline;
            }
          }

          > .grid_swatch {
            border: 0;
            outline: 0;
          }
        }

        &.upload_card {
          .btn {
            display: flex;
            height: 100%;
            justify-content: center;
            width: 100%;
          }

          h3 {
            margin: auto;
          }
        }
      }

      .royal_homes,
      .homeplay_homes {
        display: flex;
        flex-direction: column;

        .description_container {
          align-items: center;
          display: flex;
          flex-direction: column;
          margin: 0 auto 20px;
          max-width: 700px;
          text-align: center;
          width: 100%;

          h2 {
            color: $font-color !important; // override font color
            margin-bottom: 10px;
          }

          p {
            color: $font-color;
            font-weight: 400;
            max-width: 100%;

            a {
              color: $color1;
              font-weight: 700;
              text-decoration: none;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }

      .homeplay_homes .grid_cell.sample_project label {
        display: none;
      }

      .royal_homes .grid_cell.sample_project label {
        display: block;
      }

      ul {
        border-bottom: 2px solid $color2;
        display: none;
        margin-bottom: 1em;
        width: 100%;

        li {
          display: inline-block;
          font-size: 1.25em;
          font-weight: 700;
          padding: 8px 12px;
          text-transform: uppercase;

          &.ui-state-active {
            background-color: $color2;

            a {
              color: $color-white;
            }
          }
        }
      }

      @media (max-width: $screen-md-max) {
        .paginator {
          margin-left: 0;
          margin-right: 0;
          position: relative;

          .grid_cell {
            padding: 5px 0;
            width: 100%;

            &.upload_card {
              min-height: 125px;
            }
          }
        }

        ul {
          display: block;
        }
      }

      @media (max-width: $screen-md-max) and (orientation: landscape) {
        .paginator {
          .grid_cell {
            padding: 5px;
            width: 50%;
          }
        }
      }
    }

    .dialog_close {
      display: flex;

      p {
        margin-right: 10px;
      }

      .close_icon {
        align-self: center;
      }

      &::after {
        display: none;
      }
    }

    .sidebar {
      > .section {
        display: none;
      }
    }
  }
}
